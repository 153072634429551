.form {
    display: flex;
    flex-flow: row;
    column-gap: 15px;
    max-width: 1220px;
    margin: auto;
    margin-bottom: 35px;

    @media all and (max-width: 865px) {
        flex-flow: column;
        align-items: center;
        padding-top: 50px;
        margin-bottom: 5px;
    }

    .number-steps {
        width: 20.5%;
        background-color: var(--white);
        box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.08);
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        padding: 40px 20px;

        @media all and (max-width: 1230px) {
            padding: 40px 10px;
        }

        @media all and (max-width: 865px) {
            width: 95%;
            border-top-right-radius: 30px;
            border-bottom-left-radius: initial;
            padding: 20px;
            margin-bottom: 15px;
        }

        .title-step-number {
            font-family: GearedSlab-bold;
            font-size: 24px;
            text-align: center;
            margin-bottom: 32px;
            margin-top: 0;
        }

        .step-number {
            font-family: var(--font-montserrat);
            font-size: 14px;
            margin-bottom: 32px;
            margin-top: 0;
            position: relative;
            padding-left: 47px;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.3s ease;

            @media all and (max-width: 865px) {
                visibility: hidden;
                padding-left: 0 !important;
                margin-bottom: 0 !important;
                width: 100px;
            }

            @media all and (max-width: 575px) {
                width: 50px;
                max-height: 38px;
                font-size: 0;
            }

            @media all and (max-width: 375px) {
                width: 35px;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 33px;
                height: 33px;
                border: solid 2px var(--grey-two);
                color: var(--grey-two);
                border-radius: 50%;
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                font-family: GearedSlab-bold;
                font-size: 24px;
                background-color: var(--white);
                z-index: 55;
                transition: all 1s ease;

                @media all and (max-width: 865px) {
                    visibility: visible;
                    top: -50%;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @media all and (max-width: 325px) {
                    font-size: 20px;
                    width: 30px;
                    height: 30px;
                }
            }

            &::after {
                content: '';
                position: absolute;
                height: 0px;
                border: solid 2px transparent;
                transition: all 1s ease;
            }

            &.active {
                font-family: var(--font-montserrat);
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 32px;
                margin-top: 0;
                position: relative;
                padding-left: 47px;
                cursor: pointer;

                @media all and (max-width: 475px) {
                    font-size: 8px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 17px;
                    border: solid 2px var(--black);
                    height: 47px;
                    z-index: 1;

                    @media all and (max-width: 865px) {
                        display: none;
                    }
                }

                &:last-of-type {
                    &::after {
                        display: none;
                    }
                }

                &::before {
                    border: solid 2px var(--dark-grey);
                    color: var(--dark-grey);
                    border-radius: 50%;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    font-family: GearedSlab-bold;
                    font-size: 24px;
                    background-color: var(--white);

                    @media all and (max-width: 325px) {
                        font-size: 20px;
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            &.current-item {
                padding-left: 50px;

                @media all and (max-width: 865px) {
                    padding-left: 0;
                }

                &:before {
                    background-color: var(--black);
                    color: var(--white);
                }
            }

            &:hover {
                padding-left: 50px;

                @media all and (max-width: 865px) {
                    padding-left: 0;
                }
            }
        }

        .step-by-step {
            position: relative;

            @media all and (max-width: 865px) {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
            }

            @media all and (max-width: 475px) {
                margin-bottom: 10px;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 16.5px;
                transform: translateY(-50%);
                border: solid 2px var(--light-grey);
                height: 100%;

                @media all and (max-width: 865px) {
                    display: none;
                }
            }
        }

        .step-number-one::before {
            content: "1";
        }

        .step-number-two::before {
            content: "2";
        }

        .step-number-three::before {
            content: "3";
        }

        .step-number-four::before {
            content: "4";
        }

        .step-number-five::before {
            content: "5";
        }

        .step-number-six::before {
            content: "6";
        }

        .step-number-seven::before {
            content: "7";
        }

        .step-number:last-of-type {
            margin-bottom: 0;
        }
    }

    .steps {
        width: 51.25%;
        background-color: var(--white);
        box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.08);
        padding: 40px 38px;

        @media all and (max-width: 1230px) {
            padding: 40px 20px;
        }

        @media all and (max-width: 865px) {
            width: 95%;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            margin-bottom: 20px;
        }
    }

    .recap {
        width: 29.2%;
        background-color: var(--white);
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.08);
        padding: 40px 35px;

        @media all and (max-width: 1920px) {
            padding: 20px 35px;
        }

        @media all and (max-width: 1230px) {
            padding: 20px 20px;
        }

        @media all and (max-width: 865px) {
            display: none;
        }
    }
}