.content {
    max-width: 1440px;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    & * {
        box-sizing: border-box;
    }

    h2 {
        font-family: 'GearedSlab';
        font-size: 48px;
        font-weight: bold;
        margin: 0 0 30px 0;
    }
}

.Slider {
    position: relative;
    padding-top: 60px;
    margin-top: 15px;
    overflow: hidden;

    &:before {
        content: '';
        background-image: url('../../assets/img/logo-mask.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 166px;
        height: 166px;
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 20;
    }

    &:after {
        content: url('../../assets/img/btn-devis.svg');
        display: block;
        display: block;
        width: 350px;
        height: 80px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 30;
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 30;
    }

    h1 {
        position: absolute;
        bottom: 4%;
        left: 4%;
        z-index: 20;
        color: #fff;
        font-size: 56px;
        font-family: 'Lato';
        font-weight: 600;

        &::before {
            content: '';
            display: block;
            width: 30px;
            height: 35px;
            background-image: url(../../assets/img/arrow-white.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 30px;
        }
    }

    .swiper {
        height: 80vh;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 80%;
            background: linear-gradient(90deg, rgba(0, 0, 0, .6) 0, rgba(255, 255, 255, 0) 100%);
            top: 0;
            height: 100%;
            z-index: 20;
            pointer-events: none;
        }
    }

    .swiper-slide {
        img {
            width: 101%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: #fff;
    }

    button {
        position: absolute;
        bottom: 15px;
        right: 30px;
        z-index: 40;
    }
}

.Products {
    padding: 60px 0;
    h2 {
        font-family: 'GearedSlab';
        font-size: 48px;
        font-weight: bold;
        margin: 0 0 30px 0;
    }
    .product-holder {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: space-between;
        column-gap: 30px;
        row-gap: 30px;

        a {
            width: calc((100% / 3) - 20px);
            position: relative;
            display: inline-block;
            line-height: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                display: inline-block;
            }
            &::after {
                content: '';
                position: absolute;
                right: 0;
                width: 60%;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, .6) 100%);
                top: 0;
                height: 100%;
                z-index: 20;
                pointer-events: none;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                &::after {
                    opacity: 0.4;
                }
            }
        }
    }
}

.Process {
    padding: 0 0 60px;
    .process-holder {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        row-gap: 30px;
        
        .process-item {
            width: 50%;
            padding-right: 4%;

            &:nth-child(even) {
                padding-right: 0;
                padding-left: 4%;
            }

            h3 {
                display: inline-flex;
                align-items: center;
                img {
                    margin-right: 20px;
                }
            }
        }
    }
}

.Galery {
    padding-bottom: 60px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .button-primary {
        margin-bottom: 30px;
        text-decoration: none;
    }

    .galery-holder {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        column-gap: 30px;
        row-gap: 30px;

        .galery-item {
            width: calc((100% - 90px) / 4);
            height: 300px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .galery-item {
            position: relative;
            &::after {
                background: linear-gradient(90deg, #fff0, #0009);
                content: "";
                height: 100%;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                transition: all .3s ease-in-out;
                width: 80%;
                z-index: 20;
            }
        }
    }
}