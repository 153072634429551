.form .steps .step-final {
    .title-fin {
        text-align: center;
        font-family: GearedSlab-bold;
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 38px;
    }

    .sentence p {
        font-family: var(--font-montserrat);
        font-size: 15px;
        font-weight: 500;
    }

    .back {
        text-align: center;
        font-family: var(--font-montserrat);
        font-size: 15px;
        color: var(--black);
        display: flex;
        flex-flow: row;
        justify-content: center;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
    }

    .form .steps .step-final .back:hover {
        opacity: 0.7;
    }
}