body {
    margin: 0;
    font-family: Montserrat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    font-family: Montserrat;
}

.swal2-confirm {
    box-shadow: none !important;
}

.swal2-title {
    font-size: 24px;
}

.swal2-popup {
    border-radius: 30px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.t-capitalize {
    text-transform: capitalize;
}