@font-face {
    font-family: GearedSlab;
    src: url(../../assets/fonts/GearedSlab.ttf);
    font-weight: normal;
}

@font-face {
    font-family: GearedSlab-bold;
    src: url(../../assets/fonts/GearedSlab-Bold.ttf);
    font-weight: bold;
}

:root {
    --bg: #f5f4f4;
    --light-grey: #f8f8f8;
    --black: #000;
    --grey: #7b7b7b;
    --grey-two: #b1b1b1;
    --dark-grey: #322b29;
    --white: #fff;
    --bg-disabled: #e6e6e6;
    --font-montserrat: Montserrat;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.link-popup {
    color: #322b29;
    font-family: var(--font-montserrat);
    font-weight: 500;
}

.main {
    .button-primary {
        border-radius: 24px;
        background-color: var(--dark-grey);
        padding: 15px 40px;
        color: var(--white);
        font-size: 15px;
        font-family: var(--font-montserrat);
        font-weight: 700;
        border: none;
        cursor: pointer;
    }
}

.App {
    max-width: 1440px;
    margin: auto;
    box-sizing: border-box;
    padding-top: 70px;
    background-color: var(--bg);
    height: 100vh;
    position: relative;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }

    .title,
    .intro {
        text-align: center;
    }

    .title {
        font-family: GearedSlab;
        font-size: 48px;
        font-weight: bold;
        margin: 0 0 10px 0;

        @media all and (max-width: 771px) {
            font-size: 35px;
        }

        @media all and (max-width: 308px) {
            font-size: 30px;
        }
    }

    .intro {
        font-family: var(--font-montserrat);
        font-size: 15px;
        max-width: 700px;
        margin: auto;
    }

    .header-form {
        position: relative;
        display: flex;
        flex-flow: row;
        max-width: 1220px;
        margin: 0 auto 30px auto;
        justify-content: space-around;

        .logos {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            column-gap: 20px;

            @media all and (max-width: 767px) {
                flex-flow: column;
                justify-content: center;
            }

            @media all and (max-width: 460px) {
                display: none;
            }

            img {
                aspect-ratio: 9/16;
                object-fit: contain;
                object-position: center;

                @media all and (max-width: 767px) {
                    width: 45px !important;
                    height: auto !important;
                }
            }

            .logo-garantie {
                width: 41px;
                height: 91px;
            }

            .logo-pefc {
                width: 65px;
                height: 78px;
            }

            .logo-made {
                width: 76px;
                height: 78px;
            }
        }

        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 50%;
        //     left: 10%;
        //     transform: translateY(-50%);
        //     background-image: url(../../assets/img/copeaux-bois.png);
        //     background-position: center;
        //     background-size: contain;
        //     background-repeat: no-repeat;
        //     width: 207px;
        //     height: 140px;

        //     @media all and (max-width: 1310px) {
        //         left: 20px;
        //         width: 135px;
        //         height: 100px;
        //     }

        //     @media all and (max-width: 960px) {
        //         width: 75px;
        //         height: 50px;
        //         bottom: -20px;
        //         top: initial;
        //         transform: translateY(50%);
        //     }

        //     @media all and (max-width: 597px) {
        //         bottom: -50px;
        //     }

        //     @media all and (max-width: 475px) {
        //         left: 0;
        //     }

        //     @media all and (max-width: 350px) {
        //         display: none;
        //     }
        // }
    }

    img {
        max-width: 100%;
    }

    .container-buttons {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        align-items: center;

        @media all and (max-width: 575px) {
            flex-flow: column;
        }

        .link-prev {
            @media all and (max-width: 865px) {
                position: absolute;
                top: 265px;
                left: 50%;
                transform: translateX(-50%);
            }

            @media all and (max-width: 771px) {
                top: 235px;
            }

            @media all and (max-width: 767px) {
                top: 205px;
            }

            @media all and (max-width: 642px) {
                top: 235px;
            }

            @media all and (max-width: 616px) {
                top: 250px;
            }

            @media all and (max-width: 551px) {
                top: 255px;
            }

            @media all and (max-width: 499px) {
                top: 270px;
            }

            @media all and (max-width: 461px) {
                top: 275px;
            }

            @media all and (max-width: 387px) {
                top: 295px;
            }

            @media all and (max-width: 335px) {
                top: 310px;
            }

            @media all and (max-width: 314px) {
                top: 330px;
            }

            .link {
                font-size: 15px;
                color: var(--black);
                font-weight: 500;
                font-family: var(--font-montserrat);
                position: relative;
                text-decoration: none;
                border: none;
                background-color: transparent;
                font-family: var(--font-montserrat);
                font-weight: bold;
                cursor: pointer;

                @media all and (max-width: 304px) {
                    font-size: 13px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    background-image: url(../../assets/img/right-arrow.png);
                    width: 16px;
                    height: 16px;
                    background-size: contain;
                    background-position: center;
                    right: calc(100% + 10px);
                    top: 50%;
                    transform: translateY(-50%) scale(-1);
                    transition: all .3s;
                }

                &:hover::after {
                    right: calc(100% + 20px);
                }
            }
        }
    }

    .button-center {
        @media all and (max-width: 575px) {
            margin-bottom: 15px;
        }
    }

    .button-center,
    .button-end {
        padding-right: 65px;

        @media all and (max-width: 865px) {
            padding-right: 0;
        }
    }

    .button-primary {
        border-radius: 24px;
        background-color: var(--dark-grey);
        padding: 15px 40px;
        color: var(--white);
        font-size: 15px;
        font-family: var(--font-montserrat);
        font-weight: 700;
        border: none;
        cursor: pointer;
    }

    button[disabled] {
        border-radius: 24px;
        background-color: var(--bg-disabled);
        padding: 15px 40px;
        color: var(--white);
        font-size: 15px;
        font-family: var(--font-montserrat);
        font-weight: 700;
        border: none;
        cursor: not-allowed;
    }

    .button-submit {
        border-radius: 24px;
        background-color: var(--dark-grey);
        padding: 15px 40px;
        color: var(--white);
        font-size: 15px;
        font-family: var(--font-montserrat);
        font-weight: 700;
        border: none;
        cursor: pointer;
    }

    @media all and (max-width: 1920px) {
        padding: 40px 20px 20px 20px;
    }
}