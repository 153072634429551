.form .recap .card-recap {
    p {
        text-align: center;
        font-family: GearedSlab-bold;
        font-size: 24px;
        margin-top: 0;
    }

    img {
        height: 100%;
    }

    .title-recap {
        margin-bottom: 38px;
    }

    .infos {
        margin-bottom: 80px;

        @media all and (max-width: 1920px) {
            margin-bottom: 20px;
        }

        .column-infos {
            margin-bottom: 22px;
            display: flex;
            flex-flow: row;
            position: relative;

            @media all and (max-width: 1920px) {
                margin-bottom: 15px;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 27px;
                height: 31px;
                background-image: url(../../assets/img/marqueur-bistre.png);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            @media all and (max-width: 969px) {
                flex-flow: column;
            }

            .answer-info {
                @media all and (max-width: 969px) {
                    text-align: left;
                    padding-left: 47px;
                }
            }

            .dim {
                padding-left: 47px;
                text-align: left;
            }
        }

        .column-flex {
            margin-bottom: 22px;
            display: flex;
            flex-flow: column;

            @media all and (max-width: 1920px) {
                margin-bottom: 15px;
            }

            .answer-info {
                padding-left: 47px;
                text-align: left;
            }

            .pose {
                font-size: 15px;
                font-family: var(--font-montserrat);
                color: var(--black);
                font-weight: bold;

                &::before {
                    display: none;
                }
            }

            .pose-answer {
                padding-left: 0;
            }
        }

        .flex-row {
            display: flex;
            flex-flow: row;

            .answer-info {
                padding-left: 0;
            }
        }

        .column-no-margin {
            margin-bottom: 0;
        }

        @media all and (max-width: 1040px) {
            margin-bottom: 35px;
        }
    }

    .no-pin::before {
        display: none;
    }

    .info-name {
        position: relative;
        font-size: 15px;
        font-family: var(--font-montserrat);
        text-align: left;
        padding-left: 47px;
        margin-right: 5px;
        margin-bottom: 0;

        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 50%;
        //     left: 0;
        //     transform: translateY(-50%);
        //     width: 27px;
        //     height: 31px;
        //     background-image: url(../../assets/img/marqueur-bistre.png);
        //     background-position: center;
        //     background-size: contain;
        //     background-repeat: no-repeat;
        // }
    }

    .answer-info {
        font-size: 15px;
        font-family: var(--font-montserrat);
        margin-bottom: 0;
        font-weight: 500;
    }

    .file-info {
        position: relative;
        padding-left: 47px;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            width: 21px;
            height: 20px;
            background-image: url(../../assets/img/icon-file.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .logos {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        img {
            aspect-ratio: 9/16;
            object-fit: contain;
            object-position: center;
        }

        .logo-garantie {
            width: 41px;
            height: 91px;
        }

        .logo-pefc {
            width: 65px;
            height: 78px;
        }

        .logo-made {
            width: 76px;
            height: 78px;
        }
    }
}

@media all and (max-width: 1040px) {
    .form .recap .card-recap .infos {
        margin-bottom: 35px;
    }
}