@keyframes animationLine {
    0% {
        width: 0;
    }

    100% {
        width: 125px;
    }
}

.steps {
    position: relative;
}

.form .steps .step {
    .question {
        text-align: center;
        font-family: GearedSlab-bold;
        margin-bottom: 48px;
        font-size: 24px;
        margin-top: 0;
        position: relative;

        @media all and (max-width: 1920px) {
            margin-bottom: 25px;
        }

        @media all and (max-width: 1010px) {
            font-size: 20px;
        }

        @media all and (max-width: 865px) {
            font-size: 22px;
        }

        @media all and (max-width: 475px) {
            max-width: 235px;
            margin: 0 auto 48px auto;
        }

        @media all and (max-width: 375px) {
            padding-top: 15px;
            margin: 0 auto 30px auto;

        }

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 33px;
            height: 33px;
            border: solid 2px var(--dark-grey);
            border-radius: 50%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            font-family: GearedSlab-bold;
            font-size: 24px;

            @media all and (max-width: 475px) {
                left: -60px;
            }

            @media all and (max-width: 390px) {
                left: -40px;
            }

            @media all and (max-width: 340px) {
                left: -30px;
            }

            @media all and (max-width: 325px) {
                left: -25px;
                font-size: 20px;
                width: 25px;
                height: 25px;
            }

            @media all and (max-width: 315px) {
                left: 50%;
                transform: translateX(-50%);
                top: -30px;
            }
        }
    }

    .question-one::before {
        content: "1";
    }

    .question-two::before {
        content: "2";
    }

    .question-three::before {
        content: "3";
    }

    .question-four::before {
        content: "4";
    }

    .question-five::before {
        content: "5";
    }

    .question-six::before {
        content: "6";
    }

    .question-seven::before {
        content: "7";
    }

    .range-item {
        position: relative;

        .range-input {
            display: flex;
            position: relative;
            margin-top: 6px;

            input {
                position: relative;
                z-index: 2;
                cursor: pointer;
                width: 100%;
                height: 1.5rem;
                padding: 0;
                background-color: transparent;
                appearance: none;
            }

            .range-line {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 8px;
                width: calc(100% - 14px);
                background-color: var(--light-grey);
                z-index: 0;
                margin: 0 7px;
                border-radius: 50px;
                overflow: hidden;

                .active-line {
                    width: 0%;
                    position: absolute;
                    left: 0;
                    height: 100%;
                    background-color: var(--light-grey);
                    transition: all .1s ease;
                }
            }

            .dot-line {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: calc(100% - 24px);
                left: 0;
                z-index: 1;

                .active-dot {
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    cursor: pointer;
                    background-color: #ddd;
                    height: 24px;
                    width: 24px;
                    border: 4px solid green;
                    z-index: 11;
                    border-radius: 50%;
                }
            }
        }

        ul {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            margin: 0 -17px;
            padding-left: 0;
            list-style: none;
            margin-bottom: 26px;

            @media all and (max-width: 865px) {
                margin: 0 auto 26px auto;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: -17px;
                left: 10px;
                width: 90%;
                height: 5px;
                border-radius: 50px;
                background-color: var(--light-grey);

                @media all and (max-width: 515px) {
                    width: 87%;
                    left: 20px;
                }
            }

            .list-inline-item {
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 0;
                }

                display: inline-block;

                span {
                    position: relative;
                    min-width: 58px;
                    text-align: center;
                    display: block;
                    font-size: 14px;
                    padding-top: 4px;
                    word-break: break-word;
                    color: #b1b1b1;

                    @media all and (max-width: 375px) {
                        min-width: 35px;
                    }
                }

                &.active span::before {
                    background-color: var(--black);
                    width: 20px;
                    height: 20px;
                    top: -24px;
                }

                span::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }
            }
        }
    }

    .schema {
        position: relative;
        display: flex;
        justify-content: center;
        flex-flow: row;
        margin-bottom: 95px;

        @media all and (max-width: 1920px) {
            margin-bottom: 80px;
        }

        img {
            @media all and (max-width: 485px) {
                max-width: 60% !important;
            }
        }

        .arrow,
        .value {
            position: absolute;
            margin: 0;
        }

        .value {
            font-weight: bold;
            font-family: var(--font-montserrat);
            color: var(--black);
        }

        .arrow-depth {
            bottom: 0;
            left: 45px;

            @media all and (max-width: 1115px) {
                left: 25px;
            }

            @media all and (max-width: 865px) {
                left: 20%;
            }

            @media all and (max-width: 680px) {
                left: 15%;
            }

            @media all and (max-width: 485px) {
                left: 45px;
                width: 60px;
            }

            @media all and (max-width: 425px) {
                left: 35px;
            }

            @media all and (max-width: 400px) {
                left: 15px;
            }

            @media all and (max-width: 375px) {
                width: 50px;
            }

            @media all and (max-width: 300px) {
                left: 0;
            }

        }

        .value-depth {
            bottom: 30px;
            left: 25px;

            @media all and (max-width: 1115px) {
                left: 5px;
            }

            @media all and (max-width: 865px) {
                left: 15%;
            }

            @media all and (max-width: 680px) {
                left: 10%;
            }

            @media all and (max-width: 485px) {
                bottom: 10px;
                left: 20px;
            }

            @media all and (max-width: 425px) {
                left: 10px;
            }

            @media all and (max-width: 400px) {
                left: 0px;
            }

            @media all and (max-width: 375px) {
                left: -5px;
                font-size: 14px;
            }

            @media all and (max-width: 300px) {
                font-size: 12px;
                left: -10px;
                bottom: -10px;
            }
        }

        .arrow-height {
            right: 100px;
            top: 50%;
            transform: translateY(-50%);

            @media all and (max-width: 1115px) {
                right: 70px;
            }

            @media all and (max-width: 865px) {
                right: 25%;
            }

            @media all and (max-width: 650px) {
                right: 20%;
            }

            @media all and (max-width: 485px) {
                height: 140px;
            }

            @media all and (max-width: 375px) {
                height: 100px;
            }
        }

        .value-height {
            top: 50%;
            transform: translateY(-50%);
            right: 30px;

            @media all and (max-width: 1115px) {
                right: 5px;
            }

            @media all and (max-width: 865px) {
                right: 15%;
            }

            @media all and (max-width: 650px) {
                right: 2%;
            }

            @media all and (max-width: 375px) {
                right: -5px;
                font-size: 14px;
            }

            @media all and (max-width: 300px) {
                font-size: 12px;
            }
        }

        .arrow-width {
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);

            @media all and (max-width: 485px) {
                width: 150px;
            }

            @media all and (max-width: 375px) {
                width: 105px;
            }
        }

        .value-width {
            left: 50%;
            transform: translateX(-50%);
            bottom: -50px;

            @media all and (max-width: 375px) {
                font-size: 14px;
            }

            @media all and (max-width: 300px) {
                font-size: 12px;
            }
        }
    }

    .inputs-size {
        margin: 0;
        justify-content: space-around;
        max-width: initial;
        margin-bottom: 40px;

        @media all and (max-width: 1920px) {
            margin-bottom: 30px;
        }

        @media all and (max-width: 500px) {
            margin-bottom: 40px;
            flex-wrap: wrap;
        }

        .input {
            max-width: 118px;
        }

        input {
            margin-top: 5px;
            max-width: 118px !important;
            max-height: 25px;
            text-align: right;

            @media all and (max-width: 500px) {
                max-width: 90px !important;
            }
        }

        label {
            position: relative !important;
            text-indent: initial !important;
            opacity: 1 !important;
            overflow: initial !important;
            top: initial !important;
            transform: initial !important;
            color: var(--black);
            font-family: var(--font-montserrat);
            font-weight: bold;
        }
    }

    .rappel {
        text-align: center;
        font-family: var(--font-montserrat);
        font-weight: 500;
        color: var(--black);
    }
}

.step-four {
    .first-question-step-four {
        margin-bottom: 86px;
    }
}

.step-five {
    .explication {
        text-align: center;
        font-family: var(--font-montserrat);
        font-weight: 500;
        margin-bottom: 30px;
    }

    .inputs {
        margin-bottom: 65px !important;

        @media all and (max-width: 1920px) {
            margin-bottom: 30px !important;
        }

        .input {
            margin-bottom: 13px;
        }

        label {
            font-weight: 500;
            color: var(--grey);
            font-family: var(--font-montserrat);

            &.active {
                color: var(--black);
            }
        }
    }
}

.step-six {
    @media all and (max-width: 475px) {
        .inputs {
            .flex-column {
                flex-flow: column;

                input {
                    width: 95%;
                }

                #lastname,
                #zipcode {
                    margin-bottom: 15px;
                }
            }

            input {
                width: 95% !important;
            }
        }
    }
}

.step-final {
    padding: 150px 0;

    div {
        display: flex;
        flex-flow: column;
        align-items: center;

        .icon-check {
            margin-bottom: 25px;
        }

        h2 {
            font-size: 48px;
            font-family: 'GearedSlab';
            margin-top: 0;
            margin-bottom: 10px;
        }

        .sentence {
            max-width: 515px;
            font-family: var(--font-montserrat);
            font-weight: 500;
            text-align: center;
            font-size: 15px;
            margin-bottom: 20px;

            p {
                margin: 0;
            }
        }

        .back {
            font-size: 15px;
            color: var(--black);
            font-weight: 500;
            font-family: var(--font-montserrat);
            position: relative;
            text-decoration: none;

            &::after {
                content: '';
                position: absolute;
                background-image: url(../../assets/img/right-arrow.png);
                width: 16px;
                height: 16px;
                background-size: contain;
                background-position: center;
                left: calc(100% + 10px);
                top: 50%;
                transform: translateY(-50%);
                transition: all .3s;
            }

            &:hover::after {
                left: calc(100% + 20px);
            }
        }
    }
}

.range-item .range-input input:disabled~.dot-line .active-dot {
    background-color: #d9d9d9;
    border: 4px solid #b1b1b1;
}

input[type="range"] {
    &::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        margin-top: -8px;
        background-color: #0000;
        height: 15px;
        width: 15px;
        border: 4px solid #0000;
        z-index: 11;
        box-shadow: none;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        margin-top: -8px;
        background-color: #0000;
        height: 24px;
        width: 24px;
        border: 4px solid #0000;
        box-shadow: none;
        z-index: 11;
    }

    &::-webkit-slider-runnable-track,
    &::-moz-range-progress,
    &::-ms-track,
    &::-moz-range-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
        background-color: #f0f0f000;
    }

    &:focus {

        &::-webkit-slider-thumb,
        &::-moz-range-thumb {
            box-shadow: none !important;
            background-color: #f0f0f000;
        }

        &::-ms-thumb {
            background-color: #f0f0f000;
            box-shadow: none !important;
        }
    }

    &:disabled {

        &::-webkit-slider-thumb,
        &::-moz-range-thumb {
            background-color: #0000;
            border: 4px solid #0000;
        }
    }
}

/* IE*/

.form .steps {
    .step {
        .choices {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;
            margin: auto;

            .card {
                position: relative;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                margin-bottom: 20px;

                p {
                    text-align: center;
                    font-family: var(--font-montserrat);
                    font-size: 15px;
                    font-weight: 600;
                }

                .contour-img {
                    aspect-ratio: 1/1;
                    min-width: 161px;
                    background-color: var(--light-grey);
                    border-radius: 15px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: solid 1px transparent;

                    &:hover {
                        border: solid 1px var(--dark-grey);
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                    }
                }

                &.selected .contour-img {
                    border: solid 1px var(--dark-grey);
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                }
            }

            .contour-img:hover::after,
            .selected .contour-img::after {
                content: "";
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                background-image: url(../../assets/img/marqueur-bistre.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 27px;
                height: 31px;
                transition: all 0.3s ease-in-out;
            }

            .card img {
                aspect-ratio: 1/1;
                object-fit: contain;
                object-position: center;
            }
        }

        .dont-know {
            text-decoration: underline;
            cursor: pointer;
            text-align: center;
            font-size: 15px;
            font-family: var(--font-montserrat);
            font-weight: 500;
            margin-top: 0;
            display: table;
            margin: 0 auto;

            &.active {
                position: relative;
                display: table;
                margin: 0 auto;
                text-decoration: none;
                font-weight: 700;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    background-image: url(../../assets/img/ligne.svg);
                    background-repeat: no-repeat;
                    background-size: 125px 20px;
                    background-position: left;
                    width: 125px;
                    height: 20px;
                    animation: animationLine .5s forwards;
                }
            }
        }
    }

    .step-two {
        .choices {
            justify-content: space-evenly !important;
        }

        &>div {

            margin-bottom: 65px;

            @media all and (max-width: 1920px) {
                margin-bottom: 30px;
            }
        }

        .slopes-wish {
            font-weight: bold;
            font-family: var(--font-montserrat);
            text-align: center;
            margin-bottom: 23px;
        }

        .question-pentes {
            margin-bottom: 40px;
        }
    }

    .inputs {
        max-width: 395px;
        margin: auto;
        margin-bottom: 27px;

        a {
            display: flex;
            flex-flow: row;
            justify-content: center;
            font-size: 15px;
            font-weight: 500;
            color: var(--black);
            font-family: var(--font-montserrat);
        }

        .input {
            position: relative;
            width: 100%;

            @media all and (max-width: 465px) {
                text-align: center;
            }
        }
    }

    .inputs-radios {
        justify-content: space-evenly;

        .fake-input {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            color: var(--black);

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                border: 1px solid #c7c7c7;
                border-radius: 100%;
                background: #c7c7c7;
            }
        }

        .fake-input.active {
            &::after {
                content: '';
                width: 12px;
                height: 12px;
                background: var(--black);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 1px;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }

        [type="radio"]:checked+label,
        [type="radio"]:not(:checked)+label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            color: var(--black);
        }

        [type="radio"]:checked+label:before,
        [type="radio"]:not(:checked)+label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            border: 1px solid #c7c7c7;
            border-radius: 100%;
            background: #c7c7c7;
        }

        [type="radio"]:checked+label:after,
        [type="radio"]:not(:checked)+label:after {
            content: '';
            width: 12px;
            height: 12px;
            background: var(--black);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 1px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        [type="radio"]:not(:checked)+label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: translateY(-50%) scale(0);
        }
    }

    .selects {
        margin-bottom: 28px;

        .input {
            width: 50%;

            &:first-of-type {
                margin-right: 20px;
            }
        }
    }

    .inputs {
        .input {
            select {
                background-color: var(--light-grey);
                border-radius: 15px;
                padding: 10px;
                border: none;
                -webkit-appearance: none;
                font-size: 15px;
                font-weight: 500;
                font-family: var(--font-montserrat);
                color: var(--black);
                width: 100%;
            }

            #adress,
            #email,
            #phone {
                margin-bottom: 15px;
            }

            #note {
                margin-bottom: 27px;
            }

            #adress,
            #additional,
            #email,
            #phone,
            #note {
                width: 100%;
            }

            label {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                overflow: hidden;
                opacity: 0;
                text-indent: -99999px;
            }

            input {
                background-color: var(--light-grey);
                border-radius: 15px;
                padding: 10px;
                border: none;
                -webkit-appearance: none;
                font-size: 15px;
                font-weight: 500;
                font-family: var(--font-montserrat);
                color: var(--black);
            }

            textarea {
                background-color: var(--light-grey);
                border-radius: 15px;
                padding: 10px;
                border: none;
                -webkit-appearance: none;
                font-size: 15px;
                font-weight: 500;
                font-family: var(--font-montserrat);
                color: var(--black);
                resize: none;
                width: 405px;
                height: 165px;

                @media all and (max-width: 1035px) {
                    width: 375px;
                }

                @media all and (max-width: 465px) {
                    width: 300px;
                }

                @media all and (max-width: 375px) {
                    width: 250px;
                }

                @media all and (max-width: 335px) {
                    width: 200px;
                }
            }
        }

        #file-input {
            position: relative;
            text-indent: -99999px;
            max-width: 36px;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 21px;
                height: 20px;
                background-image: url(../../assets/img/icon-file.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
            }

            &.active {
                &::before {

                    background-image: url(../../assets/img/icon-file-black.svg);
                }
            }
        }

        input::placeholder {
            font-size: 15px;
            font-weight: 500;
            font-family: var(--font-montserrat);
            color: var(--grey);
        }

        .input {

            input:focus-visible,
            textarea:focus-visible {
                outline: 0;
            }
        }
    }
}

.flex-column {
    display: flex;
    flex-flow: row;
    margin-bottom: 15px;
    column-gap: 8px;
}

@media all and (max-width: 1920px) {
    .form .steps .step {
        .choices .card .contour-img {
            min-width: 135px;
            max-width: 135px;
        }
    }
}

@media all and (max-width: 1040px) {
    .form .steps .step .choices {
        justify-content: space-evenly;

        .card {
            display: flex;
            flex-flow: column;
            align-items: center;
            min-width: 200px;

            .contour-img {
                max-width: 200px;
                min-width: initial;
            }
        }
    }
}

@media all and (max-width: 950px) {
    .form .steps .step .choices {

        .card {
            min-width: 135px;

            .contour-img {
                max-width: 135px;
            }
        }
    }
}